// src/components/EditReminder.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../utils/auth';
import { motion } from 'framer-motion';

function EditReminder() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reminderTime, setReminderTime] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchReminder();
    // eslint-disable-next-line
  }, []);

  const fetchReminder = async () => {
    try {
      const response = await axios.get('https://notify-api.zapiwala.com:5050/api/reminders', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const reminder = response.data.reminders.find((r) => r.id === parseInt(id));
      if (reminder) {
        setTitle(reminder.title);
        setDescription(reminder.description);
        setReminderTime(reminder.reminder_time.slice(0, 16).replace(' ', 'T'));
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error fetching reminder:', error);
      navigate('/dashboard');
    }
  };

  const handleEditReminder = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    // No need to adjust for timezone offset; use the reminderTime as is
    const reminderTimeFormatted = reminderTime.replace('T', ' ') + ':00';

    try {
      await axios.put(
        `https://notify-api.zapiwala.com:5050/api/reminders/${id}`,
        {
          title,
          description,
          reminder_time: reminderTimeFormatted,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating reminder:', error);
      setErrorMsg('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-red-500 via-pink-500 to-purple-500 flex items-center justify-center">
      <motion.div
        className="p-8 bg-white rounded-xl shadow-xl w-full max-w-md"
        initial={{ rotateY: 90, opacity: 0 }}
        animate={{ rotateY: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Edit Reminder</h2>
        {errorMsg && (
          <motion.p
            className="text-red-500 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {errorMsg}
          </motion.p>
        )}
        <form onSubmit={handleEditReminder}>
          <div className="mb-4">
            <label className="block text-gray-700">Title:</label>
            <motion.input
              type="text"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-red-500"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter reminder title"
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description:</label>
            <motion.textarea
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-red-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter reminder description"
              whileFocus={{ scale: 1.02 }}
            ></motion.textarea>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Reminder Time:</label>
            <motion.input
              type="datetime-local"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-red-500"
              value={reminderTime}
              onChange={(e) => setReminderTime(e.target.value)}
              required
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <motion.button
            type="submit"
            className="w-full bg-red-600 text-white py-2 rounded-full font-semibold shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Update Reminder
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
}

export default EditReminder;
