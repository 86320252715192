// src/components/Home.js

import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Home() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
      <motion.div
        className="p-8 bg-white rounded-xl shadow-xl text-center"
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 text-gray-800"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          Welcome to NotifyMe
        </motion.h1>
        <motion.p
          className="text-gray-600 mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        >
          Your personal reminder app.
        </motion.p>
        <div className="flex justify-center space-x-4">
          <Link to="/register">
            <motion.button
              className="px-6 py-2 bg-blue-600 text-white rounded-full font-semibold shadow-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Register
            </motion.button>
          </Link>
          <Link to="/login">
            <motion.button
              className="px-6 py-2 bg-green-600 text-white rounded-full font-semibold shadow-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Login
            </motion.button>
          </Link>
        </div>
      </motion.div>
    </div>
  );
}

export default Home;
