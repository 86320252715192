// src/utils/auth.js

export function getToken() {
    return localStorage.getItem('token');
  }
  
  export function isLoggedIn() {
    return !!localStorage.getItem('token');
  }
  
  export function logout() {
    localStorage.removeItem('token');
  }
  