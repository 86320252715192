// src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { getToken, logout } from '../utils/auth';
import { motion } from 'framer-motion';

function Dashboard() {
  const [reminders, setReminders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!getToken()) {
      navigate('/login');
    } else {
      fetchReminders();
    }
    // eslint-disable-next-line
  }, []);

  const fetchReminders = async () => {
    try {
      const response = await axios.get('https://notify-api.zapiwala.com:5050/api/reminders', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setReminders(response.data.reminders);
    } catch (error) {
      console.error('Error fetching reminders:', error);
      if (error.response && error.response.status === 401) {
        logout();
        navigate('/login');
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this reminder?')) {
      try {
        await axios.delete(`https://notify-api.zapiwala.com:5050/api/reminders/${id}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        // Remove the deleted reminder from the state
        setReminders(reminders.filter((reminder) => reminder.id !== id));
      } catch (error) {
        console.error('Error deleting reminder:', error);
      }
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold text-gray-800">NotifyMe Dashboard</h1>
        <button
          onClick={handleLogout}
          className="text-red-500 hover:text-red-700 font-semibold"
        >
          Logout
        </button>
      </nav>
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">Your Reminders</h2>
          <Link
            to="/add-reminder"
            className="px-4 py-2 bg-blue-600 text-white rounded-full font-semibold shadow-md hover:bg-blue-700 transition duration-200"
          >
            Add Reminder
          </Link>
        </div>
        {reminders.length === 0 ? (
          <motion.p
            className="text-gray-700"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            You have no reminders.
          </motion.p>
        ) : (
          <motion.table
            className="min-w-full bg-white rounded-xl shadow overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <thead className="bg-gray-200">
              <tr>
                <th className="py-3 px-4 text-left text-gray-600">Title</th>
                <th className="py-3 px-4 text-left text-gray-600">Description</th>
                <th className="py-3 px-4 text-left text-gray-600">Reminder Time</th>
                <th className="py-3 px-4 text-left text-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reminders.map((reminder, index) => (
                <motion.tr
                  key={reminder.id}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                >
                  <td className="py-3 px-4">{reminder.title}</td>
                  <td className="py-3 px-4">{reminder.description}</td>
                  <td className="py-3 px-4">
                    {new Date(reminder.reminder_time).toLocaleString()}
                  </td>
                  <td className="py-3 px-4">
                    <Link
                      to={`/edit-reminder/${reminder.id}`}
                      className="text-blue-500 hover:text-blue-700 font-semibold mr-2"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => handleDelete(reminder.id)}
                      className="text-red-500 hover:text-red-700 font-semibold"
                    >
                      Delete
                    </button>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </motion.table>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
