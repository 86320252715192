// src/components/AddReminder.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/auth';
import { motion } from 'framer-motion';

function AddReminder() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reminderTime, setReminderTime] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleAddReminder = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    // No need to adjust for timezone offset; use the reminderTime as is
    const reminderTimeFormatted = reminderTime.replace('T', ' ') + ':00';

    try {
      await axios.post(
        'https://notify-api.zapiwala.com:5050/api/reminders',
        {
          title,
          description,
          reminder_time: reminderTimeFormatted,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      navigate('/dashboard');
    } catch (error) {
      console.error('Error adding reminder:', error);
      setErrorMsg('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex items-center justify-center">
      <motion.div
        className="p-8 bg-white rounded-xl shadow-xl w-full max-w-md"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Add Reminder</h2>
        {errorMsg && (
          <motion.p
            className="text-red-500 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {errorMsg}
          </motion.p>
        )}
        <form onSubmit={handleAddReminder}>
          <div className="mb-4">
            <label className="block text-gray-700">Title:</label>
            <motion.input
              type="text"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter reminder title"
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description:</label>
            <motion.textarea
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter reminder description"
              whileFocus={{ scale: 1.02 }}
            ></motion.textarea>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Reminder Time:</label>
            <motion.input
              type="datetime-local"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={reminderTime}
              onChange={(e) => setReminderTime(e.target.value)}
              required
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <motion.button
            type="submit"
            className="w-full bg-purple-600 text-white py-2 rounded-full font-semibold shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Add Reminder
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
}

export default AddReminder;
